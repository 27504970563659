<!--
 * @Description: 维护回访率报表
 * @Author: ChenXueLin
 * @Date: 2022-03-10 09:38:24
 * @LastEditTime: 2022-07-15 16:05:15
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="fixRevisitType">
              <e6-vr-select
                v-model="searchForm.fixRevisitType"
                :data="visiteTypeList"
                placeholder="维护回访率类型"
                title="维护回访率类型"
                virtual
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpEnum"
                placeholder="客户名称"
                title="客户名称"
                @filterChange="corpHandleFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
                virtual
                clearable
                remote
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="employeeId">
              <e6-vr-select
                v-model="searchForm.employeeId"
                :data="engineerList"
                placeholder="工程师"
                title="工程师"
                clearable
                :props="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="revisitTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="回访时间"
                ref="effectiveDatePicker"
                v-model="searchForm.revisitTime"
                title="回访时间"
                :picker-options="pickerOptions('searchForm.revisitTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="回访时间（始）"
                end-placeholder="回访时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="fixTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="维护时间"
                ref="effectiveDatePicker"
                v-model="searchForm.fixTime"
                title="维护时间"
                :picker-options="pickerOptions('searchForm.fixTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="维护时间（始）"
                end-placeholder="维护时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_fill" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <el-table-column
              v-for="(option, index) in column.optionsList"
              :key="index"
              :prop="option.fieldName"
              :label="option.fieldLabel"
              :min-width="option.width"
              :fixed="option.fixed"
              :align="option.align"
              header-align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
import {
  getStatisticalEngineerRate,
  getCorpMsg,
  getEngineer,
  getStatisticalCorpRate,
  getStatisticalTotalRate,
  exportFixRevisitRate
} from "@/api";
export default {
  name: "maintainReport",
  data() {
    return {
      searchForm: {
        corpId: "", //客户id
        fixRevisitType: 1,
        employeeId: "", //工程师
        revisitTime: [], //回访时间
        revisitTimeStart: "", //回访时间开始
        revisitTimeEnd: "", //回访时间结束
        fixTime: [], //维护时间
        fixTimeStart: "", //维护时间开始
        fixTimeEnd: "", //维护时间结束
        pageIndex: 1,
        pageSize: 20
      },
      visiteTypeList: [
        {
          id: 1,
          label: "总回访率"
        },
        {
          id: 2,
          label: "客户回访率"
        },
        {
          id: 3,
          label: "工程师回访率"
        }
      ],
      corpEnum: [], //客户名称
      engineerList: [], //工程师
      total: 0,
      loading: false,
      columnData: [],
      columnData1: [
        {
          fieldName: "revisitCount",
          display: true,
          fieldLabel: "回访数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixCount",
          display: true,
          fieldLabel: "维护数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitRate",
          display: true,
          fieldLabel: "回访率(%)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "successRevisitCount",
          display: true,
          fieldLabel: "回访成功数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "successRevisitRate",
          display: true,
          fieldLabel: "回访成功率(%)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //总回访率
      columnData2: [
        {
          fieldName: "employeeName",
          display: true,
          fieldLabel: "工程师",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitCount",
          display: true,
          fieldLabel: "回访数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixCount",
          display: true,
          fieldLabel: "维护数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitRate",
          display: true,
          fieldLabel: "回访率(%)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "successRevisitCount",
          display: true,
          fieldLabel: "回访成功数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //工程师回访率
      columnData3: [
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitCount",
          display: true,
          fieldLabel: "回访数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixCount",
          display: true,
          fieldLabel: "维护数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitRate",
          display: true,
          fieldLabel: "回访率(%)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "successRevisitCount",
          display: true,
          fieldLabel: "回访成功数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //总回访率
      quesColumnData: [
        {
          display: true,
          fieldLabel: "请问您对我司工程服务专业技能满意吗？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "questionOneOKCount",
              display: true,
              fieldLabel: "满意",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionOneYBCount",
              display: true,
              fieldLabel: "一般",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionOneNOCount",
              display: true,
              fieldLabel: "不满意",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionOneOKRate",
              display: true,
              fieldLabel: "满意率(%)",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          display: true,
          fieldLabel: "请问您对本次工程现场服务态度满意吗？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "questionTwoOKCount",
              display: true,
              fieldLabel: "满意",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionTwoYBCount",
              display: true,
              fieldLabel: "一般",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionTwoNOCount",
              display: true,
              fieldLabel: "不满意",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionTwoOKRate",
              display: true,
              fieldLabel: "满意率(%)",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          display: true,
          fieldLabel: "请问工程师是否有向您收取服务费？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "questionThreeYESCount",
              display: true,
              fieldLabel: "有总数",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionThreeNOCount",
              display: true,
              fieldLabel: "无总数",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          display: true,
          fieldLabel: "请问您对工程售后服务还有其他意见或建议吗？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "questionFourYESCount",
              display: true,
              fieldLabel: "有总数",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "questionFourNOCount",
              display: true,
              fieldLabel: "无总数",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        }
      ],
      tableData: [] // 表格数据
    };
  },
  mixins: [listPage, base, listPageReszie],
  computed: {},
  watch: {
    // 回访时间
    "searchForm.revisitTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.revisitTime = this.searchForm.revisitTime.reverse();
        }
        this.searchForm.revisitTimeStart = val[0] || "";
        this.searchForm.revisitTimeEnd = val[1] || "";
      }
    },
    // 维护时间
    "searchForm.fixTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.fixTime = this.searchForm.fixTime.reverse();
        }
        this.searchForm.fixTimeStart = val[0] || "";
        this.searchForm.fixTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.columnData = [...this.columnData1, ...this.quesColumnData];
    this.getEngineerReq();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.corpDownList(val);
        this.searchForm.corpId = "";
      }
    }, 300),
    async corpDownList(val, enumKey = "corpEnum") {
      try {
        this.corpLoading = true;
        let corpEnumRes = await getCorpMsg({
          pageIndex: 1,
          pageSize: 200,
          corpName: val
        });
        this[enumKey] = this.getFreezeResponse(corpEnumRes, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.corpLoading = false;
      }
    },
    //获取工程师下拉框
    async getEngineerReq() {
      try {
        let res = await getEngineer({});
        this.engineerList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    queryList() {
      this.tableData = [];
      if (this.searchForm.fixRevisitType == 1) {
        //总回访率
        this.columnData = [...this.columnData1, ...this.quesColumnData];
        this.getStatisticalTotalRateReq();
      }
      if (this.searchForm.fixRevisitType == 3) {
        //工程师回访率
        this.columnData = [...this.columnData2, ...this.quesColumnData];
        this.getStatisticalEngineerRateReq();
      }
      if (this.searchForm.fixRevisitType == 2) {
        //客户回访率
        this.columnData = [...this.columnData3, ...this.quesColumnData];
        this.getStatisticalCorpRateReq();
      }
    },
    //维护回访率统计报表-工程师回访率
    async getStatisticalEngineerRateReq() {
      try {
        this.loading = true;
        let res = await getStatisticalEngineerRate(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //维护回访率统计报表-总回访率
    async getStatisticalTotalRateReq() {
      try {
        this.loading = true;
        let res = await getStatisticalTotalRate(this.searchForm);
        this.tableData = [
          this.getFreezeResponse(res, {
            path: "data"
          })
        ];
        this.total = this.tableData.length;
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //维护回访率统计报表-客户回访率
    async getStatisticalCorpRateReq() {
      try {
        this.loading = true;
        let res = await getStatisticalCorpRate(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportFixRevisitRate);
    }
  }
};
</script>
<style lang="scss" scoped></style>
